import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap';

import Layout from '../components/Layout'
import { icons } from 'react-icons/lib';
import IconBox from '../components/iconBox';
export const UsPageTemplate = ({
  title,
  nombre,
  logo,
  description,
  image1,
  subTitle1,
  detail1,
  image2,
  subTitle2,
  detail2,
  subTitleUs,
  titleUs,
  descriptionUs,
  icons,
}) => (
  <section id="us">
    <Row>
      <Col className="title">
        <h1>{nombre}</h1>
        <iframe 
        src="https://www.youtube.com/embed/Voj6lVXQR_8" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen></iframe>
      </Col>
    </Row>
    <Row className="aura-sec">
      <Col md={3} xs={12} >
        <div>
          <a href="https://www.auraconstrucciones.com/">
            <img src={logo} alt="caminoalcedrovi" />
          </a>
          {description}
        </div>
      </Col>
      <Col className="elems">
        <Row>
          <Col md={6} xs={12} >
            <h2>{subTitle1}</h2>
            <p>{detail1}</p>
          </Col>
          <Col md={6} xs={12}>
            <div style={{backgroundImage: 'url('+image2+')'}}>
              <h2>{subTitle2}</h2>
              <p>{detail2}</p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Container>
      <Row className="usSection">
        <Col md={5} xs={12}>
          <h3>{subTitleUs}</h3>
          <h1>{titleUs}</h1>
          <p>{descriptionUs}</p>
        </Col>
        <Col md={7} xs={12}>
          {icons.map(icon => {
            return (
              <IconBox icon={icon} />
            )
          })}
        </Col>
      </Row>
    </Container>
  </section>
)

UsPageTemplate.propTypes = {

  title: PropTypes.string,
  nombre: PropTypes.string,
  logo: PropTypes.string,
  description: PropTypes.string,
  image1: PropTypes.string,
  subTitle1: PropTypes.string,
  detail1: PropTypes.string,
  image2: PropTypes.string,
  subTitle2: PropTypes.string,
  detail2: PropTypes.string,
  subtitleUs: PropTypes.string,
  titleUs: PropTypes.string,
  descriptionUs: PropTypes.string,
  icons: PropTypes.object,
}

const UsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout currentSlug={frontmatter.title} title={frontmatter.title} description={frontmatter.description}>
      <UsPageTemplate
        title={frontmatter.title}
        nombre={frontmatter.nombre}
        logo={frontmatter.logo}
        description={frontmatter.description}
        image1={frontmatter.image_1}
        subTitle1={frontmatter.sub_title_1}
        detail1={frontmatter.detail_1}
        image2={frontmatter.image_2}
        subTitle2={frontmatter.sub_title_2}
        detail2={frontmatter.detail_2}
        subTitleUs={frontmatter.subTitleUs}
        titleUs={frontmatter.titleUs}
        descriptionUs={frontmatter.descriptionUs}
        icons={frontmatter.icons}
      />
    </Layout>
  )
}

UsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default UsPage

export const pageQuery = graphql`
  query UsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "us-page" } }) {
      frontmatter {
        title
        nombre
        logo
        description
        image_1
        sub_title_1
        detail_1
        image_2
        sub_title_2
        detail_2
        subTitleUs
        titleUs
        descriptionUs
        icons {
          icon
          subTitle
          title
          description
        }
      }
    }
  }
`