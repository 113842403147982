import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

class IconBox extends Component {
    constructor(props) {
        super(props)
        this.icon = props.icon
    }

    render() {
        return (
            <Row>
                <Col md="2">
                    <img src={this.icon.icon}/>
                </Col>
                <Col>
                    <h3>{this.icon.subTitle}</h3>
                    <h4>{this.icon.title}</h4>
                    <p>{this.icon.description}</p>
                </Col>
            </Row>
        )
    }
}
export default IconBox